import { bool, node } from 'prop-types';

const Brand = ({ center = false, children }) => {
  const classNames = ['flex', 'items-center'];
  if (center) classNames.push('justify-center');
  else classNames.push('justify-start');

  return <div className={classNames.join(' ')}>{children}</div>;
};

Brand.propTypes = {
  center: bool,
  children: node.isRequired,
};

Brand.displayName = 'Brand';

export default Brand;

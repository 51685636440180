'use client';

import { node, object, string } from 'prop-types';
import Image from 'next/image';
import Link from 'next/link';

import { Brand } from '@personly/libs-widgets';
import { useLocalizedPathname } from '@personly/libs-hooks';
import { useSchemes } from '@personly/libs-providers';

const BrandContent = ({ alt, children, languages }) => {
  const localizedPathname = useLocalizedPathname(languages);
  const { schemeHandle } = useSchemes();
  const scheme = schemeHandle.toLowerCase();

  const src = `/images/logos/colorized-${scheme}.svg`;

  return (
    <Brand>
      <Link
        prefetch={false}
        href={localizedPathname('/')}
        className="flex flex-row"
      >
        <Image alt={alt} height={22} width={26} src={src} />
        &nbsp;&nbsp;
        {children}
      </Link>
    </Brand>
  );
};

BrandContent.propTypes = {
  alt: string.isRequired,
  children: node.isRequired,
  languages: object.isRequired,
};

export default BrandContent;

'use client';

import Image from 'next/image';
import { string } from 'prop-types';

import { useSchemes } from '@personly/libs-providers';

const CompanyImage = ({ alt }) => {
  const { schemeHandle } = useSchemes();
  const scheme = schemeHandle.toLowerCase();

  const src = `/images/components/navbar/astateful-${scheme}.svg`;

  return <Image alt={alt} width={101} height={26} src={src} />;
};

CompanyImage.propTypes = { alt: string.isRequired };

export default CompanyImage;
